/* Side bar */

.op-sidebar {
  position: fixed;
  -webkit-position: fixed;
  -moz-position: fixed;
  height: 100%;
  width: 20rem;
  padding-top: 4.5rem;
  padding-bottom: 3rem;
  background-color: #ffffff;
  /* background-color: rgb(246, 246, 246); */
  overflow-y: auto;
  overflow-x: hidden;
  transition: all 0.2s linear;
  z-index: 100 !important;
  opacity: 1 !important;
  /* box-shadow: 1px 10px 2px #f3f3f3; */
  -webkit-overflow-scrolling: touch;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
@media screen and (max-width: 900px) {
  .op-sidebar {
    margin-left: -20rem;
    box-shadow: none;
  }
}

.op-sidebar .list {
  border-radius: 0 1.5rem 1.5rem 0;
  width: 19rem;
  margin: 3px 0;
  color: var(--ct-green-normal);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all 0.2s linear;
  overflow-x: hidden;
}
.op-sidebar .list:hover, .op-sidebar .list:focus {
  background: #f1f5f5;
}
.op-sidebar .goback {
  height: 3rem !important;
}
.op-sidebar .list-group-item {
  line-height: 18px !important;
  padding: 11px 20px !important;
}
.op-sidebar .list-group-item.active {
  background: var(--ct-green-normal);
  color: #ffffff !important;
}
