.epb-item-view {
  margin: 10px 0;
  padding: 10px 15px 20px 15px;
  border-top: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid rgb(231, 231, 231);

  img {
    width: 100%;
    margin-bottom: 15px;
  }
}