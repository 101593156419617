/* .watch-cc-customize-btn {
  margin-left: .5em;
}
.watch-cc-customize-btn i {
  font-size: 1.2em;
  color: white;
  color: rgb(160, 160, 160);
  position: absolute;
  background: black;
  right: -.2em;
  top: -.2em;
} */
.watch-cc-container {
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.watch-cc-text {
  position: relative;
  width: max-content;
  font-weight: bold;
  text-align: center;
  line-height: normal;
  white-space: normal;
  padding: .1em .6em;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: "Open Sans";
  src: url(../../../../../assets/cc-fonts/OpenSans-Medium.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Tahoma";
  src: url(../../../../../assets/cc-fonts/tahoma.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Times New Roman";
  src: url(../../../../../assets/cc-fonts/times-new-roman.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Calibri";
  src: url(../../../../../assets/cc-fonts/calibri.ttf);
  font-weight: bold;
}

@font-face {
  font-family: "Helvetica";
  src: url(../../../../../assets/cc-fonts/helvetica.ttf);
  font-weight: bold;
}
