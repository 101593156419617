.ct-dnd.dnd-li {
  &.dragging {
    .ct-dnd.dnd-item-con {
      transition: all 200ms ease-in-out;
    }
  }

  .ct-dnd.dnd-item-con {
    display: flex;
    align-items: center;
    position: relative;
    outline: 0;
    padding-left: 5px;
    background: inherit;
  
    &.disabled {
      .ct-dnd.dnd-icon {
        display: none;
      }
  
      .ct-dnd.dnd-item {
        width: 100%;
        padding-left: 10px;
      }
    }
  
    &.dragging {
      box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.171);
    }
  
    .ct-dnd.dnd-icon {
      width: 30px;
      display: flex;
      align-items: center;
      i.material-icons {
        color: rgb(133, 133, 133);
      }
    }
  
    .ct-dnd.dnd-item {
      cursor: auto;
      width: calc(100% - 30px);
    }
  }
}