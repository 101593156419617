@keyframes wmodal-show-t {
  0% {
    top: 10em;
    opacity: 0;
  }
  100% {
    top: 5em;
    opacity: 1;
  }
}
@keyframes wmodal-hide-t {
  0% {
    top: 5em;
    opacity: 1;
  }
  100% {
    top: 10em;
    opacity: 0;
  }
}
.watch-modal {
  position: fixed;
  width: 100vw;
  height: 100%;
}
.watch-modal[data-modal-type="modal-hide"] {
  visibility: hidden;
}
.wml-filter {
  display: absolute;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.623);
}
.wmodal-box-gen {
  position: absolute;
  width: max-content;
  height: max-content;
  padding: .5em .5em 1em .5em;
  max-width: calc(100vw - 2em);
  background: rgb(59, 59, 59);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(49, 49, 49, 0.671);
  overflow: hidden;
}
.wmodal-box-gen[data-position*=left] {
  left: 1em;
}
.wmodal-box-gen[data-position*=right] {
  right: 1em;
}
.wmodal-box-gen[data-position*=top] {
  top: 5em;
  animation: wmodal-show-t .1s linear;
}
.watch-modal[data-modal-type="modal-before-hide"] {
  .wmodal-box-gen[data-position*=top] {
    animation: wmodal-hide-t .1s linear;
  }
}
.wml-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .3em;
  min-width: 10em;
  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: .3em .6em 1em .6em;
    i {
      margin-right: .4em;
    }
  }
}
.wml-close-btn {
  height: max-content;
  span {
    color: white;
    font-size: 1.3em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: .2em;
  }
  &:hover {
    >span {
      background: rgb(107, 107, 107);
    }
  }
  i {
    color: white;
    font-size: 1.1em;
  }
}
.wml-content {
  display: block;
  position: relative;
  width: 100%;
}
