$muted: rgb(93, 93, 93);
$celadon: rgb(55, 83, 83);
$teal: var(--ct-green-normal);
$highlighted: var(--ct-text-highlight);


.ct.ct-text {
  line-height: normal;
  color: rgb(65, 65, 65);
  width: auto;

  &.textCenter {
    text-align: center;
  }

  &.justified {
    text-align: justify;
  }
  
  &.textRight {
    text-align: right;
  }

  &.textLeft {
    text-align: left;
  }

  &:hover {
    &.hoverUnderlined {
      text-decoration: underline;
    }

    &.hoverTeal {
      color: $teal;
    }

    &.hoverHighlighted {
      color: $highlighted;
    }
  }

  &.fluid {
    width: 100%;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.capitalize {
    text-transform: capitalize;
  }

  &.bold {
    font-weight: bold;
  }
  &:not(.bold) {
    font-weight: normal;
  }

  &.underline {
    text-decoration: underline;
  }

  &.italic {
    font-style: italic;
  }

  &.muted {
    color: $muted;
  }

  &.celadon {
    color: $celadon;
  }

  &.white {
    color: white;
  }

  &.teal {
    color: $teal;
  }

  &.highlighted {
    color: $highlighted;
  }

  /** Size **/
  &.normal {
    font-size: 12px;
  }

  &.medium {
    font-size: 15px;
  }

  &.big {
    font-size: 20px;
  }

  &.large {
    font-size: 22px;
  }

  &.huge {
    font-size: 28px;
  }

  &.limitedLine {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
}