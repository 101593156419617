.ctp.control-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .ctp.bottom-bar {
    background: rgba(0, 0, 0, 0);
    // transition: background 100ms linear;
  }

  .ctp.control-btns {
    height: 40px;
    padding-bottom: 5px;

    .left, .right {
      width: 50%;
      z-index: 1;
    }

    .left {
      padding-left: 5px;
    }

    .right {
      padding-right: 5px;
      justify-content: flex-end;
    }
    
  }

  .ctp.center-area {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .ctp.cc-con {
      z-index: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 10px;
    }
  }
}

.ctp.wrapper.interact {
  &.show {
    .ctp.bottom-bar {
      background: linear-gradient(to top,rgba(0, 0, 0, 0.658) 5%,rgba(0, 0, 0, 0.288));
    }
    .ctp.center-area {
      background: linear-gradient(to top,rgba(0, 0, 0, 0.288) 5%,rgba(0, 0, 0, 0) 70%);
    }
  }
}