.ctp.menu.settings {
  bottom: 60px;
  right: 20px;
  width: auto;
  min-width: 18em;
  height: auto;
  max-height: calc(100% - 60px - 70px);
  overflow-y: auto;
  padding: 3px 0;
  border-radius: 5px;

  color: white;
  // background-color: rgba(27, 27, 27, 0.897);
  background-color: rgba(47, 47, 47, 0.945);
  display: none;
  font-size: 0.9em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.301);

  &.open {
    display: block;
  }

  .ctp.settings-menu {
    width: 100%;
    display: flex;
    flex-direction: column;

    .ctp.settings-menuitem {
      width: 100%;
      color: white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 10px;
      white-space: nowrap;
      transition: background 150ms linear;

      &:focus {
        outline: none;
        color: rgb(0, 187, 187);
        background-color: rgba(39, 39, 39, 0.438);
      }

      &:hover {
        background-color: rgba(39, 39, 39, 0.575);
      }

      &:active {
        background: rgb(34, 34, 34);
      }

      &.bordered {
        border-bottom: 1px solid grey;
      }

      &.go-back {
        border-bottom: 1px solid grey;
        margin-bottom: 5px;
        padding: 10px 10px;
        background-color: rgba(47, 47, 47, 0.945);
        position: sticky;
        top: 0;
        &:focus .ctp.menuitem-text,
        &:hover .ctp.menuitem-text {
          text-decoration: underline;
        }
      }

      span {
        display: flex;
        align-items: center;
        width: max-content;
      }

      .current-value {
        margin-left: 15px;
        font-size: 0.85em;
      }

      .ctp.check-icon {
        margin-right: 10px;
        opacity: 0;
        &.checked {
          opacity: 1;
        }

        i {
          font-size: 1em;
        }
      }

      .ctp.right-arrow {
        margin-left: 5px;
        i {
          font-size: 1em;
        }
      }

      .ctp.menuitem-text {
        font-weight: bold;
      }
    }
  }
}