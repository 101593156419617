.watch-tab-helper {
  position: fixed;
  top: -10em;
  left: 0;
  opacity: 0;
  &:focus {
    opacity: 1;
    top: 0;
  }
  &:focus-within {
    opacity: 1;
    top: 0;
  }
}
