.watch-search-input-bar {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;
  height: 50px;
  box-shadow: 0 0 8px rgba(17, 17, 17, 0.801);
}
.search-input-container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid transparent;
  transition: border .1s ease-in;
  &:focus-within {
    border-color: #b6b6b6;
  }
}
.search-input-bar-btn {
  position: relative;
  color: white;
  .search-input-bar-btn-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 49px;
    font-size: 1.7em;
    border-bottom: 1px solid transparent;
  }
  &:hover {
    >.search-input-bar-btn-content {
      background-color: rgb(68, 68, 68);
      border-color: rgb(68, 68, 68);
    }
  }
  &:focus {
    >.search-input-bar-btn-content {
      background-color: rgb(68, 68, 68);
      border-color: rgb(68, 68, 68);
    }
    outline: none;
  }
  &:active {
    >.search-input-bar-btn-content {
      background-color: transparent;
      border-color: transparent;
    }
  }
}
.search-input-bar-btn[action=search] {
  .search-input-bar-btn-content {
    padding: .5em 0 0 .7em;
    border: none;
    height: 49px;
    color: var(--ct-text-white-hover);
  }
}
.search-input-bar-btn[action=clear] {
  .search-input-bar-btn-content {
    padding: .5em 0 0 0;
    border: none;
    height: 49px;
    color: var(--ct-text-white-hover);
  }
}
.search-input-bar-btn[action=filter] {
  .search-input-bar-btn-content {
    font-size: 1.6em;
  }
}
.search-input-bar-btn[action=close] {
  .search-input-bar-btn-content {
    font-size: 1.3em;
    width: 90px;
  }
}
.watch-search-input {
  background: transparent;
  color: white;
  border: none;
  height: 55px;
  width: 100%;
  font-size: 18px;
  padding: 14px .5em .5em .2em;
  &::placeholder {
    color: rgb(155, 155, 155);
  }
  &:focus {
    border-color: #b6b6b6;
    background: transparent !important;
    outline: none;
  }
}
