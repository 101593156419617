/**
 * Config 
 * https://github.com/tobiasahlin/SpinKit
 */
:root {
  --ct-loader-size: 60px;
  --ct-loader-size-small: 19px;
  --ct-loader-color: rgb(73, 73, 73);
  --ct-loader-color-bright: rgb(226, 226, 226);
  --ct-loader-color-green: rgb(223, 230, 230);
}

.ct-loader {
  min-height: 400px;
}

/*  Flow
  <div className="ct-loader .flow">
    <div className="ct-loader .flow-dot"></div>
    <div className="ct-loader .flow-dot"></div>
    <div className="ct-loader .flow-dot"></div>
  </div>
*/
.ct-loader .flow {
  width: calc(var(--ct-loader-size) * 1.3);
  height: calc(var(--ct-loader-size) * 1.3);
  display: flex;
  justify-content: space-between;
}
.ct-loader .flow[small=true] {
  width: calc(var(--ct-loader-size-small) * 1.3);
  height: calc(var(--ct-loader-size-small) * 1.3);
}

.ct-loader .flow-dot {
  width: 25%;
  height: 25%;
  background-color: var(--ct-loader-color);
  border-radius: 50%;
  animation: ct-loader-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite both;
}
.ct-loader .flow-dot[data-dark=true] {
  background-color: var(--ct-loader-color-bright);
}

.ct-loader .flow-dot:nth-child(1) { animation-delay: -0.30s; }
.ct-loader .flow-dot:nth-child(2) { animation-delay: -0.15s; }

@keyframes ct-loader-flow {
  0%, 80%, 100% {
    transform: scale(0.3); }
  40% {
    transform: scale(1); 
  }
}