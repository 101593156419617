.ct-filter.d-input-con {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 0 0 20px;

  background: rgb(246, 246, 246);
  border-radius: 20px;
  overflow: hidden;

  &.grey {
    background: rgb(236, 236, 236);

    &:focus-within {
      background: rgb(225, 233, 235);
    }
  }

  &:focus-within {
    background: rgb(235, 237, 238);
  }

  input {
    background: rgba(0,0,0,0);
    width: 100%;
    border: none;
    font-size: 15px;
    padding: 10px 0;

    &:focus {
      outline: none;
    }
  }

  .ct-filter.reverse-btn{
    transition: transform 100ms linear;
    transform: rotateZ(0);

    &.reversed {
      transform: rotateZ(180deg);
    }
  }
}

