.ct-error-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
  }

  .ct-ew-body {
    max-width: 600px;

    .ct-ew-info {
      display: flex;
      flex-direction: column;

      .ct-ew-code {
        font-size: 150px;
        line-height: 150px;
        font-family: var(--ct-font-narrow);
        color: var(--ct-green-normal);
        font-weight: bold;

        i {
          font-size: 100px;
        }
      }

      .ct-ew-descrip {
        font-size: 20px;
        line-height: 26px;
      }
    }

    .ct-ew-actions {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        margin: 5px 0;
      }

      .pico.pico-btn {
        margin: 0 0 30px 0;
        width: 200px !important;
        span {
          justify-content: center !important;
        }
      }
    }
  }
}

.ct-error-wrapper.dark {
  .ct-ew-body {
    .ct-ew-info {
      .ct-ew-code {
        color: var(--ct-text-highlight);
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .ct-error-wrapper {
    padding: 80px 10px 10px 10px;
    justify-content: flex-start;
    .ct-ew-body {
      .ct-ew-info {
        align-items: center;
        .ct-ew-header {
          text-align: center;
          font-size: 20px;
          margin-top: 0;
        }
      }
    }
  }
}