.watch-bg {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  background: var(--watch-page-bg-color);
  color: white;
}


