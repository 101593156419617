.ct-video-row {
  top: 4.5em;
  position: fixed;
}
.ct-video-con {
  height: 100%;
}
.ct-video-container {
  width: 100%;
  max-height: 100%;
  position: relative;
}
.ct-video {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.watch-player-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.watch-player-wrapper[data-blur=true] {
  background: rgba(0, 0, 0, 0.719);
}
