.ctp.wrapper.interact {
  cursor: none;
  z-index: 2;

  &.static {
    z-index: 0;
  }

  // &:focus-within, 
  &.show {
    transition: none;
    cursor: auto;
    opacity: 1;

    .ctp.ctrl-bar-con {
      transform: translateY(0px);
    }

    .ctp.dismissible {
      opacity: 1;
    }
  }

  .ctp.dismissible {
    opacity: 0;
    transition: opacity 200ms linear;
  }

  .ctp.action-bar-con {
    display: flex;
    width: 100%;
    height: 50px;
    z-index: 10;
    // background: rgba(0, 92, 92, 0.781);
    // background: rgba(53, 53, 53, 0.781);
    background: linear-gradient(to bottom,rgba(0, 0, 0, 0.459) 10%,rgba(0, 0, 0, 0));
    // background: linear-gradient(to bottom,rgba(9, 88, 88, 0.596) 5%,rgba(0, 0, 0, 0));
  }

  .ctp.ctrl-bar-con {
    width: 100%;
    height: calc(100% - 50px);
    z-index: 10;
    transform: translateY(45px);
    transition: transform 100ms linear;
    background: rgba(0, 0, 0, 0);
  }
}