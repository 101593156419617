.ct-nh-tab-panel {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  background-color: white;
  .ct-nh-tab-title-con {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    padding-bottom: 10px;
  }
}

.ct-nh-tabs-con {
  height: auto;
  display: flex;
  flex-direction: row;
  margin-left: 2rem;

  .ct-nh-tab {
    padding: 1px 0 0 0;
    height: max-content;
    overflow: hidden;
    border-bottom: 3px solid transparent;
    transition: all .2s ease-in;
    
    span {
      height: auto;
      display: flex;
      position: relative;
      font-size: 19px;
      line-height: 19px;
      font-weight: 500;
      color: rgb(104, 104, 104);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      
      font-family: var(--ct-font-google);
      // text-transform: uppercase;
      padding: 3px .7em 11px .7em;
    }

    &:hover, &:focus {
      color: black;
      border-color: rgb(93, 93, 93);
    }
    &:focus {
      outline: 1px solid rgb(7, 150, 143);
    }
    &.active {
      border-color: var(--ct-green-normal);

      span {
        font-weight: 500;
        color: var(--ct-green-normal);
      }
    }
  }
}