#ct-epub-list-screen-con {
  h1 {
    font-size: 40px;
    margin: 50px 0;
    min-height: auto !important;
    padding: 0;
    background: white;
    position: sticky;
    bottom: 20vh;
    width: 100%;
    text-align: center;
  }

  #ct-epb-list {
    width: 45%;
    box-shadow: 0 0 8px 2px rgba(38, 41, 41, 0.068);
  }

  #ct-epb-poster {
    width: 55%;
    img {
      filter: blur(3px);
    }
  }
}