.ct-nav-header-sb-trigger-con {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  height: 50px;
  padding-left: 5px;
}

#ct-layout-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .ct-layout-main {
    width: 100%;

    &.transition {
      transition: padding 200ms linear;
    }

    &.padded-240 {
      padding-left: 240px;
    }

    &.padded-50 {
      padding-left: 50px;
    }

    .ct-layout-fill {
      height: calc(100% - 50px);
    }

    &.tab-header {
      .ct-layout-fill {
        height: calc(100% - 87px);
      }
    }
  }
}