html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: #272727;
  margin: 0;
  /* font-size: 0.875rem !important; */
  font-size: 1.0rem !important;
  font-family: -apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol" !important;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root, .ct-app-insight-provider {
  height: 100%;
  width: 100%;
}

a:focus, button:focus, div[tabindex="0"]:focus, *[contenteditable="true"]:focus {
  outline-style: solid;
  outline-width: 2px;
  outline-color:  rgb(7, 150, 143);
}

a:active, button:active {
  outline: none;
}

p {
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}
h1, h2, h3, h4, h5 {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}