#cookie-agreement-inner-wrapper {
  .cookie-acp-title {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .policy-container {
    background: rgb(245, 245, 245);
  }
  .policy-btn {
    color: #328383;
    margin: 0 4px;
    padding-bottom: 2px;
  }
  .cookie-acp-btns {
    padding: 0 0 20px 0 !important;
  }
}

@media screen and (max-width: 520px) {
  #cookie-agreement-inner-wrapper {
    .cookie-acp-title .welcome-txt {
      display: none;
    }
  }
}