#cp-course-info {
  .number {
    display: block;
    color: rgb(48, 134, 134);
    font-size: 35px;
    margin-bottom: 0;
    line-height: 40px;
  }

  .name {
    font-size: 30px;
    line-height: normal;
    white-space: normal;
    margin: 0 0 20px 0;
    font-family: var(--ct-font-google);
    color: var(--ct-text-primary);
  }

  .term {
    color: rgb(87, 87, 87);
    font-size: 17px;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .description {
    color: rgb(36, 36, 36);
    font-size: 15px;
    line-height: 20px;
    margin-top: 10px;
    padding: 5px 5px 5px 7px;
    border-left: 4px #0983839a solid;
    background-color: #e2f1f179;
    white-space: pre-line;
  }

  .cp-action-bar {
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
}