$ctp-share-popup-bgcolor: rgb(53, 53, 53);

.ctp.share-root {
  position: relative;
  right: 0;

  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ctp.share-popup {
    z-index: 10;
    position: absolute;
    top: 30px;
    right: 0;
    background-color: $ctp-share-popup-bgcolor;
    width: 350px;
    border-radius: 5px;
    padding: 0 10px 10px 10px;
    color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.548);
    overflow-y: scroll;

    h5 {
      position: sticky;
      top: 0;
      padding: 10px 0;
      margin: 0;
      background-color: $ctp-share-popup-bgcolor;
    }

    &.screenshot {
      width: 25em;
      top: 10px;
    }

    .ctp.share-image {
      width: 100%;
      margin-bottom: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .ctp.share-link {
      width: 100%;
      margin-bottom: 10px;
      input {
        width: 100%;
        color: white;
        border: none;
        background-color: rgb(100, 100, 100);
        padding: 10px;
        border-radius: 5px;

        &:focus {
          outline: none;
        }

        &::selection {
          color: white;
          background-color: rgb(25, 133, 133);
        }
      }
    }
  }
}
