.ct-epb.player-modal {
  background: black !important;
  h3 {
    width: max-content !important;
  }
  .ct-epb.player-modal-player-con {
    width: 100%;
    height: 600px;
    max-height: 80vh;
  }
}