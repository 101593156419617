.ct-info-n-li.inl-container {
  top: 0;
  left: 0;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: row;

  .ct-info-n-li.info {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 40%;
    height: 100%;
    padding: 20px 10px 20px 20px;
    -webkit-overflow-scrolling: touch;
    font-family: var(--ct-font-google);
    overflow-x: hidden;
    overflow-y: auto;
  }

  .ct-info-n-li.list {
    width: 60%;
    height: 100%;
    padding: 0;
    background: rgb(251, 251, 251);

    .ct-info-n-li.scroll-view {
      height: 100%;
      background: rgb(251, 251, 251);
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

@media screen and (max-width: 1000px) {
  .ct-info-n-li.inl-container {
    flex-direction: column;
    height: max-content;

    .ct-info-n-li.info {
      width: 100%;
      height: max-content;
      padding-right: 20px;
    }
    .ct-info-n-li.list {
      width: 100%;
      height: max-content;
    }
  }
}