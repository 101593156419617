

table {
    width: 100%;
    border-collapse: collapse;
}

thead {
    & th {
      text-align: left;
      border-bottom: 2px solid black;
    }

    & button {
        border: 0;
        border-radius: none;
        font-family: inherit;
        font-weight: 700;
        font-size: inherit;
        padding: 0.5em;
        margin-bottom: 1px;

        &.ascending::after {
            content: '↓';
            display: inline-block;
            margin-left: 1em;
        }

        &.descending::after {
            content: '↑';
            display: inline-block;
            margin-left: 1em;
        }
    }
}

// dropdown selection breadcrumbs
.rs-picker-has-value {
    & .rs-btn .rs-picker-toggle-value,
    & .rs-picker-toggle .rs-picker-toggle-value {
        color: var(--ct-green-small);
        font-weight: 500;
    }
}

// dropdown selection items
.rs-picker-cascader-menu-item {
    &:hover,
    &:focus,
    &.rs-picker-select-menu-item-focus,
    &.rs-picker-cascader-menu-item-focus {
        color: var(--ct-green-small);
        font-weight: 500;
    }
}

tbody {
    & td {
        padding: 0.5em;
        border-bottom: 1px solid #ccc;

        & a {
            color: var(--ct-green-small);
            font-weight: 500;

            &:hover {
                color: var(--ct-green-normal);
            }

            &[target="_blank"]::after {
                content: 'open_in_new';
                font-family: 'Material Icons';
                padding-left: 2px;
                vertical-align: bottom;
                -webkit-font-feature-settings: 'liga';
            }
        }
    }

    & tr:hover {
        background-color: #eee;
    }
}

.tableBar {
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-flex;
    flex-wrap: wrap;
    font-size: 15px;

    & button {
        border: 0;
        border-radius: none;
        font-family: inherit;
        font-size: inherit;
        padding-left: 1em;
        padding-right: 1em;
        margin-bottom: 1px;
        background-color: #cccccc00;

        &:hover {
            text-decoration: underline;
        }
    }

    & span {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.pageNumber {
    padding-top: 6px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.pageLabel {
    padding-top: 6px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
    border: 0px;
}

.searchBox {
    height: 35px;
    width: 200px;
}

.pageBox {
    height: 35px;
    width: 35px;
}
