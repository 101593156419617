.ctp.wrapper.non-interact {
  z-index: 1;

  .ctp.e-v-popup-con {
    .ctp.e-v-volume-con {
      position: absolute;
      top: 10%;
      width: 100%;

      .ctp.e-v-volume {
        background-color: rgba(36, 36, 36, 0.61);
        width: 100px;
        border-radius: 10px;
        padding: 5px 10px;
        font-size: 15px;
      }
    }
    .ctp.e-v-popup-icon {
      background-color: rgba(0, 0, 0, 0.274);
      border-radius: 100%;
      padding: 1.5em;
      pointer-events: none;
      opacity: 0;
      animation: 600ms linear 0ms 1 normal forwards running show-and-fade;

      i { font-size: 50px; }
    }
  }
}

@keyframes show-and-fade {
  0% { opacity: .7; transform: scale(0.5); }
  100% { opacity: 0; transform: scale(1.5);}
}