@keyframes show-and-fade {
  0% {
    opacity: .7;
    transform: scale(0.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
.wbp-btn-container {
  position: relative;
}
.wbp-btn {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:hover {
    outline: none;
    >.big-play-button-content {
      color: var(--ct-text-highlight);
    }
  }
  &:focus {
    outline: none;
    >.big-play-button-content {
      outline: auto;
    }
  }
}
.big-play-button-content {
  color: rgba(0, 206, 206, 0.753);
  transition: var(--ct-transition-color);
  -o-transition: var(--ct-transition-color);
  -moz-transition: var(--ct-transition-color);
  -webkit-transition: var(--ct-transition-color);
  i {
    font-size: 11em;
  }
}
.wbp-btn[data-show-and-fade] {
  background-color: rgba(0, 0, 0, 0.274);
  opacity: 0;
  animation: 600ms linear 0ms 1 normal forwards running show-and-fade;
  border-radius: 100%;
  padding: 1.5em;
  pointer-events: none;
  .big-play-button-content {
    i {
      font-size: 6em;
      color: rgba(255, 255, 255, 0.979);
    }
  }
}
