img.ct-pl-icon {
  margin-right: 10px;
  pointer-events: none;
  width: 19px;

  &.small {
    width: 15px;
  }

  &.normal {
    width: 21px;
  }

  &.large {
    width: 28px;
  }

  &.big {
    margin-right: 14px;
    width: 36px;
  }
}

i.ct-pl-icon {
  margin-right: 8px !important;

  &.small {
    font-size: 15px !important;
  }

  &.normal {
    font-size: 20px !important;
  }

  &.large {
    font-size: 25px !important;
  }

  &.big {
    margin-right: 12px !important;
    font-size: 32px !important;
  }
}