.sp-input-con {
  background: rgb(246, 246, 246);
  border-radius: 20px;
  overflow: hidden;

  &:focus-within {
    background: rgb(236, 242, 243);
  }

  #sp-input {
    background: rgba(0,0,0,0);
    width: 100%;
    border: none;
    font-size: 15px;

    &:focus {
      outline: none;
    }
  }
}