/**
 * Stylesheet for General Modal
 */

.ct-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 63, 63, 0.116);
  z-index: 2000;

  .ct-mdl-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);
  }

  .ct-mdl-con {
    background-color: white;
    border-radius: 5px;
    width: 30vw;
    min-width: 400px;
    box-shadow: 0 0 10px rgba(27, 27, 27, 0.089);
    overflow-x: hidden;
    overflow-y: auto;
    animation: ctmdl-show 50ms linear;
    -o-animation: ctmdl-show 50ms linear;
    -moz-animation: ctmdl-show 50ms linear;
    -webkit-animation: ctmdl-show 50ms linear;
    z-index: 1;
    display: none;

    &.large {
      width: 60vw;
    }

    &.middle {
      width: 40vw;
      min-width: 400px;
    }

    &.ctmdl-open {
      display: block;
      animation: ctmdl-show 50ms linear;
      -o-animation: ctmdl-show 50ms linear;
      -moz-animation: ctmdl-show 50ms linear;
      -webkit-animation: ctmdl-show 50ms linear;
    }

    &.ctmdl-close {
      animation: ctmdl-hide 50ms linear;
      -o-animation: ctmdl-hide 50ms linear;
      -moz-animation: ctmdl-hide 50ms linear;
      -webkit-animation: ctmdl-hide 50ms linear;
    }

    // Header
    .ct-mdl-h-con {
      left: 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: .5em .5em .5em 1em;
      background-color: white;

      h3 {
        font-size: 1.8em;
        font-family: var(--ct-font-narrow);
        color: var(--ct-text-title);
        padding: 0;
        margin: 0vw;
        text-transform: uppercase;
      }
    }

    // Children
    .ct-mdl-text-con {
      padding: .2em 1em;
      font-family: var(--ct-font-google);
      max-height: 500px;
      width: 100%;
      overflow: hidden;
      .ct-mdl-text {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }
    }

    .ct-mdl-act {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 1em;
      background-color: white;
    }
  }
}

@keyframes ctmdl-show {
  0% { transform: scale(.5); opacity: 0; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(1); opacity: 1; }
}
@-o-keyframes ctmdl-hide {
  0% { transform: scale(.5); opacity: 0; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(1); opacity: 1; }
}
@-moz-keyframes ctmdl-show {
  0% { transform: scale(.5); opacity: 0; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(1); opacity: 1; }
}
@-webkit-keyframes ctmdl-show {
  0% { transform: scale(.5); opacity: 0; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes ctmdl-hide {
  0% { transform: scale(1); opacity: 1; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(.5); opacity: 0; }
}
@-o-keyframes  ctmdl-hide {
  0% { transform: scale(1); opacity: 1; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(.5); opacity: 0; }
}
@-moz-keyframes ctmdl-hide {
  0% { transform: scale(1); opacity: 1; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(.5); opacity: 0; }
}
@-webkit-keyframes ctmdl-hide {
  0% { transform: scale(1); opacity: 1; }
  70% { transform: scale(.8); opacity: .2; }
  100% { transform: scale(.5); opacity: 0; }
}