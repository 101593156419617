.ct-media-card {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;

  .info-con {
    padding: 5px;
  }

  &:not(.row) {
    margin: 0 5px 10px 5px;
    flex-direction: column;
    width: 250px;
    min-width: 250px;
    height: 190px;
    min-height: 134px;
    border-radius: 5px;
    // box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }

  &:not(.dark):hover {
    background-color: rgba(247, 248, 248, 0.664);
  }

  &.row {
    margin: 0;
    padding: 10px;
    white-space: nowrap;
    flex-wrap: nowrap;
    flex-direction: row;

    &:not(:last-child) {
      border-bottom: 1px solid rgb(236, 236, 236);
    }

    .info-con {
      width: calc(100%);
      padding: 5px 10px 5px 15px;
    }
  }

  &.dark {
    background: rgba(0, 0, 0, 0);
    
    &.row {
      border-color: rgb(53, 53, 53);
    }

    .info-con {
      .media-name {
        color: white;
      }
      .media-description {
        color: rgb(206, 206, 206);
      }
    }
  }

  &:hover, &:active {
    .info-con {
      .media-name {
        color: teal;
      }
    }
  }
}