.ct-course-card-ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;

  -webkit-flex-direction: column;
  flex-direction: column;

  &:not(.row) {
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
}

.ct-course-card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0 5px 10px 5px;
  width: 250px;
  min-width: 250px;

  &.disabled {
    pointer-events: none;
  }
  
  &:not(.row) {
    // background-color: rgb(250, 250, 250);
    min-height: 134px;
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);

    &:hover {
      // background-color: rgb(250, 250, 250);
      background-color: rgb(245, 245, 245);
    }
  }

  &.fluid {
    width: 100%;
  }

  &.row {
    margin: 0;
    &:not(:last-child) {
      border-bottom: 1px solid rgb(236, 236, 236);
    }

    // .title {
    //   flex-direction: row;
    // }
  }

  &:hover, &:active {
    .title {
      .number {
        text-decoration: underline;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: normal;
  }
}