.ct-file-btn {
  width: 100%;
  margin-bottom: 10px !important;
  &:hover .ct-file-btn-content {
    border-color: rgb(170, 170, 170);
    background-color: rgb(248, 250, 250);
  }

  &:focus, &:hover {
    .ct-file-btn-content {
      .ct-file-btn-icon.download {
        display: block;
      }
      .ct-file-btn-icon.file {
        display: none;
      }
    }
  }

  .ct-file-btn-content {
    width: 100%;
    padding: 10px;
    display: flex;
    border-radius: 5px;
    border: solid 1px rgb(211, 211, 211);
    transition: border 200ms linear, background 100ms linear;
  
    .ct-file-btn-icon {
      padding-top: 5px;
      width: 35px;
      &.download {
        display: none;
        .material-icons, i.fas {
          color: teal !important;
        }
      }

      .material-icons, i.fas {
        color: rgb(61, 61, 61) !important;
        font-size: 30px !important;
      }
    }
  
    .ct-file-btn-name {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      color: rgb(61, 61, 61);

      .name {
        font-weight: bold;
        font-size: 17px;
        font-family: var(--ct-font-google);
        text-align: left;
        line-height: normal;
      }
  
      .description {
        margin-top: 5px;
        color: rgb(83, 83, 83);
        text-align: left;
      }
    }
  }
}