$border: solid 1px rgb(214, 214, 214);

.ct-fragment {
  width: 100%;
  
  &.raised {
    border-radius: 5px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }

  &.shadowed {
    box-shadow: 0 0 1rem .2rem rgba(0, 0, 0, 0.089);
  }

  &.sticky {
    top: 0;
    position: sticky;
    z-index: 10;
    background-color: white;
  }

  &.bordered {
    border: $border;
  }

  &.border-top {
    border-top: $border;
  }

  &.border-right {
    border-right: $border;
  }

  &.border-bottom {
    border-bottom: $border;
  }

  &.border-left {
    border-left: $border;
  }

  &.dark {
    background-color: rgb(29, 29, 29);
    color: white;
  }

  &.scroll-y {
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.scroll-x {
    overflow-x: auto;
    overflow-y: hidden;
  }
}