.ipl-upl-tb-con {
  margin-top: 20px;
  border-radius: 10px;
  border: 1px solid rgb(214, 214, 214);
  max-height: 350px;

  .tb-row:hover {
    background: rgb(244, 244, 244);
  }

  .tb-cell {
    padding: 5px 0;
    display: flex;
    flex-direction: column;
    max-width: 400px;

    &.auto-width {
      max-width: 600px;
    }

    &.header {
      font-weight: bold;
      font-size: 14px;
      flex-direction: row;

      &.video1::after {
        margin-top: 2px;
        content: 'Primary';
        font-size: 12px;
        margin-left: 8px;
        color: var(--ct-green-normal);
      }
    }

    &.actions {
      flex-direction: row;
      justify-content: flex-end;
    }

    span.name {
      display: flex;
      font-family: var(--ct-font-narrow);
      color: rgb(46, 46, 46);
      font-size: 17px;
      line-height: normal;
      overflow: hidden;

      i.fa-file-video {
        margin: 2px 10px 0 0;
        color: var(--ct-green-normal);
      }
    }

    span.size {
      display: block;
      color: grey;
      font-size: .9em;
      margin: 5px 0 0 5px;
    }

    span.progress-txt {
      font-weight: bold;
      color: rgb(0, 153, 153);

      &.failed {
        color: red;
      }
    }
  }
}