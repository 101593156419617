.ctp.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ctp.menu {
  z-index: 20;
  position: absolute;
}

// .ctp.main-wrapper {

// }