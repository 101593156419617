.ctp.ct-player-con {
  display: flex;
  flex-direction: column;
  width: max-content;
  height: max-content;

  .ctp.ct-player {
    position: relative;
    overflow: hidden;

    background-color: black;
    color: white;

    font-size: 14px;
    line-height: 19px;

    &.md {
      font-size: 17px;
      line-height: 21px;
    }

    &.lg {
      font-size: 20px;
      line-height: 24px;
    }

    &:focus {
      outline: none;
    }
  }
}