#epb-preview-ch-sel {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 5px !important;
  border: none !important;

  .MuiListItemText-root {
    .MuiTypography-body1 {
      font-weight: bold !important;
      font-size: 16px !important;
      color: rgb(71, 71, 71);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}