////////////////////////////////////////////////////////////
// Cover Picker
////////////////////////////////////////////////////////////
.ct-img-picker-con {
  width: 100%;
  display: flex;

  .ct-img-picker-imgs-con {
    width: 55%;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ct-img-picker-imgs {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: flex-start;

    .ct-img-picker-img-con {
      position: relative;
      margin: 5px;
      cursor: pointer;

      img {
        width: 100px;
      }

      .ct-img-picker-img-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        &:hover {
          background-color: rgba(0, 0, 0, 0.287);
        }

        i {
          font-size: 35px;
          color: white;
        }
      }

      &[data-current=true] .ct-img-picker-img-wrapper {
        background-color: rgba(0, 0, 0, 0.61);
      }
    }
  }

  .ct-img-picker-cover {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
      width: 100%;
    }
  }
}