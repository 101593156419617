.ctp.action-bar {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  // padding-top: 15px;

  .left, .right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .right {
    width: 7em;
    justify-content: flex-end;
    padding-right: 10px;
  }

  .left {
    width: calc(100% - 7em);
    padding-left: 5px;

    .media-name {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 10px;
      width: 100%;
      font-size: 1.1em;

      .ctp.ct-logo {
        width: 20px;
        margin-right: 5px;
      }

      a {
        color: white;
        width: max-content;
        padding-left: 5px;
        padding-top: 3px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: 600;
      }
    }
  }

  .ctp.screenshot-btn {
    background-color: rgb(73, 73, 73);
    padding: 8px;
    border-radius: 100%;
  }
}