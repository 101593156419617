.ct-homep.section-con {
  &:not(:first-child) {
    border-top: 1px solid rgb(238, 238, 238);
  }

  .ct-homep.section-title-con {
    padding: 20px 10px 10px 10px;

    .ct-homep.section-title {
      font-size: 25px;
      color: rgb(42, 42, 42);
      width: auto;
      max-width: max-content;
      margin: 0 10px 0 0;
      font-weight: bolder;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      padding-bottom: 5px;
      line-height: normal;
    }

    a.ct-homep.section-title:hover {
      span.content {
        text-decoration: underline;
      }
    }
  }
}

@media screen and (min-width : 660px) {
  .ct-layout-main:not(.mobile) {
    .ct-homep.section-con:hover {
      .ct-homep.section-title-con a.ct-homep.section-title span.content::after {
        content: ' > See more';
        color: rgb(104, 104, 104);
        font-size: 16px;
        margin-left: 10px;
        font-weight: normal;
        text-decoration: none;
        display: inline-block;
        animation: expand-padding-h 160ms linear;
      }
    }
  }
}