/**
 * Stylesheet for ClassTranscribe Header
 */

#ct-nav-header {
  position: relative;
  opacity: 1;
  width: 100%;
  height: max-content;
  z-index: 1000;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: #ffffff;

  &.ct-nav-dark {
    background: black;
  
    .ct-header-brand {
      margin-left: 1em;
    }
    
    .profile-menu .trigger {
      color: rgb(241, 241, 241);
      &:hover {
        color: rgb(219, 219, 219);
      }
    }

    /* Fix pico color accessibility until pico v2 */
    /* https://github.com/picocss/pico/pull/261 */
    .pbtn-icon {
      color: var(--ct-mobile-menu);
    }
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }

  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
  }

  &.bordered {
    border-bottom: var(--ct-border-color);
  }

  &.shadowed {
    box-shadow: 0 0 10px rgba(165, 185, 187, 0.308);
  }

  &.tabHeader {
    height: 87px;
    overflow-y: hidden;
  }

  &.toolbarHeader {
    height: 80px;
    overflow-y: hidden;
  }

  #ct-nh-primary {
    height: 50px;
  }

  /* Subtitle */
  .ct-h-subtitle {
    font-size: 1.6em;
    margin-left: .8rem;
    color: rgb(83, 83, 83);
    white-space: nowrap;
  }

  .ct-header-left-elem, 
  .ct-header-right-elem {
    position: relative;
    height: inherit;
  }

  /* Profile menu Trigger */
  .profile-menu {
    margin-right: 1em;

    .trigger {
      color: inherit;
      outline: none;
      padding: 0;
      height: 2em;
      border-radius: .6em !important;
      color: rgb(70, 70, 70);
      i {
        font-size: 1rem;
      }
      &:hover {
        background: none !important;
        color: rgb(0, 0, 0);
      }
    }

    .menu-item {
      font-size: 1.2rem !important;
    }
  }
}

.profile-img,
.profile-img-alt {
  min-width: 30px;
  cursor: pointer;
  outline: none;
  text-transform: uppercase;

  &:hover, 
  &:focus {
    box-shadow: 0 0 1px 2px #8f8f8f63;
  }
}

.profile-img-alt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 23px;
  text-align: center;
  color: white;
  background: rgb(63, 63, 63);

  &.menu {
    margin-bottom: 10px;
    height: 45px;
    width: 45px;
    font-size: 25px;
  }
}

.profile {
  width: 20em;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;}
.profile p {
  white-space: normal;
  text-align: center;}
  .profile p span {
    font-size: .8em;}
.profile img {
  margin-bottom: .5em;
  width: 4em;
}

/* Brand */
.ct-header-brand {
  width: auto;
  height: max-content;
  margin-left: .5em;
  display: flex;

  &.small img {
    height: 20px;
    width: 140px;
  }

  &.logo img {
    height: 28px;
    width: auto;
  }

  &.logo.medium img {
    height: 32px;
    width: auto;
  }

  img {
    height: 28px;
    width: 170px;
  }

  &.large img {
    height: 40px;
    width: auto;
  }
}

.Mui-disabled[id=profile] {
  opacity: 1 !important;
}



@media screen and (max-width: 600px) {
  #ct-nav-header {
    .ct-nav-light {
      box-shadow: none;
    }
    .ct-nav-dark .ct-header-brand {
      margin-left: .5em;
    }
    .profile-menu {
      margin-right: .5em;
    }
  }

  .ct-header-brand {
    &.large img {
      height: 28px;
    }
  }
}
