.watch-ctrl-bar-container {
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 4.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 10px 1em 10px;
  background: var(--watch-page-bg-color-alpha);
}
.watch-ctrl-bar-left-elems {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: max-content;
  width: 50%;
  justify-content: flex-start;
}
.watch-ctrl-bar-right-elems {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: max-content;
  width: 50%;
  justify-content: flex-end;
}
@media screen and (max-width: 550px) {
  .watch-ctrl-bar-container {
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    height: 9em;
  }
  .watch-ctrl-bar-left-elems {
    width: 100%;
    padding-bottom: .5em;
    border-bottom: var(--ct-border-dark);
    justify-content: space-between;
  }
  .watch-ctrl-bar-right-elems {
    width: 100%;
    padding-top: 1em;
    justify-content: flex-start;
    justify-content: space-between;
  }
}
