.msp-bg {
  width: 100%;
  min-width: 1000px;
  height: 100%;

  .msp-content {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
  }
}