.holder {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.holder .logo {
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  height: 100%;
  -webkit-animation: fadein 736ms;
  -moz-animation: fadein 736ms;
  animation: fadein 736ms;
}
@-webkit-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}
@-moz-keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}
@keyframes fadein {
  from {opacity: 0;}
  to {opacity: 1;}
}

.holder .logo h1 {
  font-weight: 900;
  font-size: 2.5em;
  color: rgb(59, 145, 148) !important;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif !important;
}
.holder .logo h5 {
  font-size: 1.7em;
  font-weight: bold;
  color: rgb(92, 97, 97) !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}