.msp-me-name-con {
  color: rgb(58, 58, 58);
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 20px;
  font-family: var(--ct-font-narrow);

  .name {
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    color: var(--ct-green-normal);
  }
}