////////////////////////////////////////////////////////////
// ePub List
////////////////////////////////////////////////////////////

.ct-epb.chapter-list {
  padding: 0 0 50% 10px;
}

////////////////////////////////////////////////////////////
// ePub Chapters Item
////////////////////////////////////////////////////////////
.ct-epb.sch.epb-data-item {
  position: relative;
  width: 100%;
  padding: 0 2px 0 0;
  transition: padding .2s linear;

  &:last-child {
    padding-bottom: 20px;
  }

  .item-info {
    position: relative;
    margin-left: 95px;
    padding: 5px;
    cursor: pointer;
    transition: all 100ms linear;
    border-radius: 5px;

    &:hover {
      background-color: rgb(248, 248, 248);
    }

    .item-time-con {
      padding-top: 10px;
      position: absolute;
      left: 27%;
      //right: calc(100% + 10px);
      top: 10px;
      pointer-events: none;
      display: flex;
      flex-direction: column;
  
      .item-time {
        font-size: 14px;
        color: grey;
  
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
    
    .item-img-con {
      position: relative;
      min-width: 150px;
      height: max-content;

      img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        width: 220px;
        object-fit: contain;
        cursor: zoom-in;
      }
    }

    .item-text {
      position: relative;
      white-space: pre-wrap;
      padding-left: 10px;
      padding-top: 10px;
      font-size: 14px;
      line-height: 23px;
      // height: 92px;
      overflow: hidden;
    }
  }

  &.sub {
    .item-info {
      margin-left: 185px;
    }
  }

  .item-actions {
    width: 100%;
    transition: opacity .2s ease-in;
    opacity: 0;
    pointer-events: none;
    border-radius: 0 20px 20px 0;
    padding-left: 20px;
    // background-color: rgb(245, 245, 245);
  }

  &:hover {
    .item-actions {
      pointer-events: visible;
      opacity: 1;
    }
  }


  &.padded-actions {
    .item-actions {
      padding-left: 100px;
    }
  }
}



////////////////////////////////////////////////////////////
// ePub Chapter Item
////////////////////////////////////////////////////////////
.ct-epb.sch.ch-item {
  position: relative;
  width: 100%;
  flex: 0 0 auto;
  padding-bottom: 20px;
  border-top: solid 1px rgb(236, 236, 236);

  &.fold {
    .ch-item-title .ch-item-expand-btn {
        transform: rotate(180deg);
    }
  }

  .ch-item-title-con {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
    background: linear-gradient(to bottom,white 90%,rgba(255, 255, 255, 0));
    padding: 5px 0 10px 90px;
    margin-top: 20px;
    // animation: epb-ch-title-show 200ms linear;

    &.sub {
      margin: 0 !important;
      padding: 15px 0 15px 180px;
      // animation: none !important;
      .ch-item-act-btn {
        top: 15px;
      }
    }

    .ch-item-title {
      font-weight: bolder;
      margin: 0;
      padding: 5px 10px;
      font-size: 32px;
      width: 100%;
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }

    .ch-item-act-btn {
      position: absolute;
      top: 9px;
      left: 55px;
      opacity: .8;
      transition: opacity .2s ease-in;

      &.padded-1 {
        left: 10px;
      }

      &.padded-2 {
        left: 140px;
      }

      &.padded-3 {
        left: 95px;
      }
    }

    .ch-item-expand-btn {
      transition: transform 100ms linear;
    }

    &:hover .ch-item-act-btn, 
    &:focus-within .ch-item-act-btn {
      opacity: 1;
    }
  }


  ////////////////////////////////////////////////////////////
  // Compact Chapter View
  ////////////////////////////////////////////////////////////
  .ch-item-compact-txt {
    margin: 0 0 20px 100px;
    animation: epb-ch-hide 200ms linear;
    font-size: 14px;
    line-height: 23px;
  }

  .ch-item-ol {
    position: relative;
    flex: 0 0 auto;
    animation: epb-ch-show 200ms linear;
  }

  ////////////////////////////////////////////////////////////
  // ePub Sub-Chapter Item
  ////////////////////////////////////////////////////////////
  &.sub {
    border-top: none;

    .ch-item-title-con {
      position: relative;
      margin-top: 10px;
      z-index: 0;
    }

    .ch-item-compact-txt {
      margin-left: 190px;
    }
  }
}

@keyframes epb-ch-hide {
  0% { padding: 100px 0; opacity: 0; }
  100% { padding: 0px 0; opacity: 1; }
}
@keyframes epb-ch-show {
  0% { opacity: 0; max-height: 50px; }
  100% { opacity: 1; max-height: 1000px; }
}
@keyframes epb-ch-title-show {
  0% { 
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  } 100% {
    padding-top: 15px;
    padding-bottom: 15px;
    opacity: 1;
  }
}