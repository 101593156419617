.wml-share-url {
  background: rgb(92, 92, 92);
  font-size: 1.3em;
  border-radius: 5px;
  padding: .5em .5em .5em 1em;
}

.wml-share-url input {
  color: white;
  width: 30em;
  min-width: 0;
}
.wml-share-url input:focus {
  outline: auto;
  outline-width: 1px;
  outline-style: dotted;
}
.wml-share-url-cpy {
  margin-left: 1em;
}
.wml-share-url-cpy span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  color: var(--ct-text-highlight);
  font-weight: bold;
  padding: .5em;
  border-radius: 5px;
  background: rgb(49, 49, 49);
}
.wml-share-url-cpy i {
  margin-right: .3em;
}
.wml-share-url-cpy:hover > span {
  background: rgb(70, 70, 70);
}
.wml-share-url-cpy:active > span {
  background: rgb(20, 20, 20);
}


.wml-share-radio {
  display: flex;
  margin-top: 2em;
  align-items: center;
  margin-left: 1em;
}

.wml-share-radio .ct-radio .radio-slider {
  background: rgb(92, 92, 92);
}

.wml-share-radio-label {
  font-size: 1.2em;
  margin: 0 1em;
}

.wml-share-time {
  opacity: .5;
  align-items: center;
  margin-right: 30px;
}

.wml-share-time[data-show=true] {
  opacity: 1;
}

.wml-share-time input {
  color: white;
  background: rgb(92, 92, 92);
  padding: .2em .5em;
  border-radius: 5px;
  width: 4em;
  text-align: center;
  font-size: 1.2em;
}

@media screen and (max-width: 550px) {
  .wml-share-time {
    display: none;
  }
}
