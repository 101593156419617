.ct-scroll-area-con {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .ct-scroll-area {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ct-sa-to-top-btn {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: none;
    z-index: 20;
    width: max-content;
    height: max-content;

    span.pico-btn-con {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.274);
      i {
        font-size: 35px !important;
      }

      &:hover {
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.384);
      }
    }

    &.top {
      top: 20px;
      display: block;
    }

    &.left {
      right: auto;
      display: block;
      left: 20px;
    }

    &.right {
      display: block;
      right: 20px;
      left: auto;
    }

    &.is-top {
      display: none;
    }
  }
}